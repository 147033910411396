<template>
  <div>
    <v-container
      :class="{
        mobile: $vuetify.breakpoint.smAndDown,
        page: $vuetify.breakpoint.smAndDown
      }"
    >
      <DesktopBackButton
        v-if="$vuetify.breakpoint.mdAndUp"
        class="mb-2"
      />
      <div
        v-if="loaded && staff && staff.detuserid && fnActionByName(uIActions.LIST_APPLICATIONS)"
        class="d-flex mt-3"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <div class="flex-shrink-1 order-md-2 mb-3">
          <StaffDetailsCard
            :staff="staff"
            :location-id="locationId"
            :staff-id="staffId"
            :disable-verify-access="true"
          />
        </div>
        <div
          class="order-md-1 flex-grow-1"
          v-if="fnActionByName(uIActions.LIST_APPLICATIONS)"
        >
          <v-card class="msa-list">
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <div>
                <v-text-field
                  class="data-table--search-field"
                  label=""
                  outlined
                  prepend-inner-icon="search"
                  placeholder="Search apps"
                  @input="onSearchChangeDebounced"
                  aria-label="filter applications list"
                  hide-details
                />
              </div>
            </div>
            <div
              style="display: block"
              v-if="fnActionByName(uIActions.LIST_APPLICATIONS)"
            >
              <v-list two-line>
                <div
                  v-for="app in applications"
                  :key="app.applicationCode"
                  :id="`add-application--row-${app.applicationCode}`"
                >
                  <v-list-item @click="navigateToApplication(app)">
                    <v-list-item-avatar tile>
                      <AppIcon :app-name="app.icon" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <span class="listTitle">{{ app.applicationDesc }}</span>
                        </v-col>
                      </v-row>
                      <!-- <v-list-item-subtitle>{{ listAvailableTasks(app.taskList) | csv }}</v-list-item-subtitle> -->
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                        icon
                        ripple
                        :aria-label="'select ' + app.applicationDesc"
                      >
                        <v-icon
                          class="msa-grey"
                        >
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-list>
            </div>
          </v-card>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { AppIcon } from "@nswdoe/doe-ui-core";
import DesktopBackButton from "../components/DesktopBackButton";

import { mapGetters } from "vuex";
import searchDebounce from "@/mixins/searchDebounce";
import StaffDetailsCard from "@/components/StaffDetailsCard.vue";
import {UI_ACTIONS} from "@/utils/constants";

export default {
  components: {
      StaffDetailsCard,
    AppIcon,
    DesktopBackButton
  },
  props: {
    "staffId": {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loaded: false,
      uIActions: UI_ACTIONS
    };
  },
  computed: {
    ...mapGetters({
      locationId: "selectedSchoolId",
      locType: "selectedLocType",
      fnActionByName: 'getActionByName'
    }),
    fullName() {
      return this.staff.firstname + " " + this.staff.lastname;
    },
    staff() {
      return (
        this.$store.getters.staff(this.locationId, this.staffId) ||
        this.$store.getters.addStaff
      );
    },
    applications() {
      return this.$store.getters.addableApplications(
        this.locationId,
        this.staffId
      );
    }
  },

  created: async function() {

    const locationType = this.$store.getters.locations.find(
          l => l.id === this.locationId
          ).locType;

    //console.log("Before calling fetchAppsForStaffAtLocation from created func: locType-" + locationType);


    if (this.$store.getters.applications(this.locationId, this.staffId).length === 0) {
      await this.$store.dispatch("fetchAppsForStaffAtLocation", {
        locationId: this.locationId,
        locType: locationType,
        staffId: this.staffId
      });
    }
    this.loaded = true;
    window.scroll(0, 0);
  },
  mixins: [searchDebounce],
  methods: {
    navigateToApplication(app) {
      const path = `./application/${app.applicationCode}`;
      this.$router.push({ path: path });
    }
  }
};
</script>

<style scoped>
.listTitle {
  line-height: 1.5rem;
}

</style>
